// eslint-disable-next-line no-shadow
export enum IPublicEnumTransitionType {
  /** 节点更新后重绘处理 */
  REPAINT
}

/**
 * @deprecated use IPublicEnumTransitionType instead
 */
export enum TransitionType {
  /** 节点更新后重绘处理 */
  REPAINT
}